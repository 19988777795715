import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";

//Components
import CookieConsent from "./components/cookieConsent/cookieConsent";
import RouteChangeTracker from "./components/routeChangeTracker";

//Assets
import cityList from "./pages/localSEOPages/cityList.json";

//Scripts
import ScrollToTop from "./scripts/scrollToTop";

//React-lazy-loading
const Home = React.lazy(() => import("./pages/home"));
const AboutUs = React.lazy(() => import("./pages/aboutUs"));
const Contact = React.lazy(() => import("./pages/contact"));
const AboutCookies = React.lazy(() => import("./pages/aboutCookies"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy"));
const ServiceavtalVillkor = React.lazy(() =>
  import("./pages/serviceavtalVillkor")
);
const WebDevelopment = React.lazy(() => import("./pages/webDevelopment"));
const Seo = React.lazy(() => import("./pages/seo"));
const Serviceavtal = React.lazy(() => import("./pages/serviceavtal"));
const NewsOverview = React.lazy(() => import("./pages/newsOverview"));
const News = React.lazy(() => import("./pages/news"));
const Error404 = React.lazy(() => import("./pages/error404"));
const Kundgaranti = React.lazy(() => import("./pages/kundgaranti"));
const BookMeeting = React.lazy(() => import("./pages/bookMeeting"));
const Projects = React.lazy(() => import("./pages/projects"));
const HjalpMedHemsida = React.lazy(() =>
  import("./pages/landingPages/hjalpMedHemsida")
);
const LocalWebbyraPage = React.lazy(() =>
  import("./pages/localSEOPages/localWebbyraPage")
);
const LocalSeoPage = React.lazy(() =>
  import("./pages/localSEOPages/localSEOPage")
);
const OffertHemsida = React.lazy(() => import("./pages/offertHemsida"));
const WordpressWebbyra = React.lazy(() =>
  import("./pages/landingPages/wordpressWebbyra")
);
const ProjectInquiry = React.lazy(() => import("./pages/projectInquiry"));
const HelloTherePopup = React.lazy(() =>
  import("./components/popup/helloTherePopup")
);

export const CookieContext = React.createContext(null);
export const DropdownContext = React.createContext(null);
export const TopBannerContext = React.createContext(null);

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

function App() {
  const [cookieConsent, setCookieConsent] = useState({
    functionalCookies: Cookies.get("FunctionalCookieConsent"),
    performanceCookies: Cookies.get("PerformanceCookieConsent"),
    marketingCookies: Cookies.get("MarketingCookieConsent"),
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [newsList, setNewsList] = useState([]);

  const forceUpdate = useForceUpdate();

  const request_url =
    process.env.NODE_ENV === "production"
      ? `https://dopetechstudio.com/api/news-slugs` //Production
      : `https://dopetechstudio.com/api/news-slugs?isDev=true`; //Development

  useEffect(() => {
    fetch(request_url, {
      method: "GET",
    })
      .then((result) => result.json())
      .then((json) => setNewsList(json));
  }, [request_url]);

  useEffect(() => {
    setTimeout(async () => {
      if (
        window.location.hostname !== "localhost" &&
        !window.location.hostname.includes("192.168") &&
        !window.location.hostname.includes("10.0")
      ) {
        TagManager.initialize({ gtmId: "GTM-WD7ZJ43" });
      }
    }, 3500);
  }, []);

  useEffect(() => {
    if (Cookies.get("PopupClosed") === undefined) {
      setTimeout(() => {
        setPopupVisible(true);
      }, 22000);
    }
  }, []);

  const closePopup = () => {
    setPopupVisible(false);
    Cookies.set("PopupClosed", true, { expires: 2 });
  };

  return (
    <div className="App">
      <CookieContext.Provider value={{ cookieConsent, setCookieConsent }}>
        <DropdownContext.Provider
          value={{ dropdownVisible, setDropdownVisible }}
        >
          <TopBannerContext.Provider value={{ popupVisible, setPopupVisible }}>
            <ScrollToTop />

            {popupVisible && Cookies.get("CookieModalShown") === "true" && (
              <HelloTherePopup closeButtonClicked={() => closePopup()} />
            )}

            <CookieConsent forceUpdate={forceUpdate}></CookieConsent>
            <Suspense fallback={<div></div>}>
              <div>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/om-oss" element={<AboutUs />} />
                  <Route path="/nyheter" element={<NewsOverview />} />
                  <Route path="/projekt" element={<Projects />} />
                  <Route path="/kontakt" element={<Contact />} />
                  <Route path="/om-cookies" element={<AboutCookies />} />
                  <Route
                    path="hjalp-med-hemsida"
                    element={<HjalpMedHemsida />}
                  />
                  <Route
                    path="/personuppgiftspolicy"
                    element={<PrivacyPolicy />}
                  />
                  <Route
                    path="/allmana-villkor-serviceavtal"
                    element={<ServiceavtalVillkor />}
                  />
                  <Route path="/hemsidor" element={<WebDevelopment />} />
                  <Route path="/sokmotoroptimering" element={<Seo />} />
                  <Route
                    path="/serviceavtal-hemsida"
                    element={<Serviceavtal />}
                  />
                  <Route path="/kundgaranti" element={<Kundgaranti />} />
                  <Route
                    path="/kostnadsfri-konsultation"
                    element={<BookMeeting />}
                  />
                  <Route path="/offert-hemsida" element={<OffertHemsida />} />
                  <Route
                    path="/wordpress-webbyra"
                    element={<WordpressWebbyra />}
                  />
                  <Route
                    path="/projektforfragan"
                    element={<ProjectInquiry />}
                  />

                  {/* ALL NEWS (Fetched from WP) */}
                  {newsList.map((object) =>
                    object.slug !== "" ? (
                      <Route
                        key={object.slug}
                        path={`/${object.slug}`}
                        element={<News />}
                      />
                    ) : (
                      <></>
                    )
                  )}

                  {/* LOCAL WEBBYRÅ AND SEO PAGES */}
                  {cityList.map((object) => (
                    <>
                      <Route
                        key={object.slug}
                        path={object.slug}
                        element={<LocalWebbyraPage />}
                      />
                      <Route
                        key={object.slug}
                        path={`/seo-${object.codeName}`}
                        element={<LocalSeoPage />}
                      />
                    </>
                  ))}

                  <Route path="*" element={<Error404 />} status={404} />
                </Routes>
              </div>
            </Suspense>
          </TopBannerContext.Provider>
        </DropdownContext.Provider>
      </CookieContext.Provider>
      <RouteChangeTracker />
    </div>
  );
}

export default App;
